import isUserSignedInLoader from "@loaders/isUserSignedInLoader";
import EmailPage from "@pages/signUp/EmailPage";
import PasswordPage from "@pages/signUp/PasswordPage";
import { Navigate } from "react-router-dom";

const signUpRoutes = [
  {
    index: true,
    element: <Navigate to="password" replace />,
  },
  {
    element: <EmailPage />,
    path: "email",
  },
  {
    element: <PasswordPage />,
    loader: isUserSignedInLoader,
    path: "password",
  },
];

export default signUpRoutes;
