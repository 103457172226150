import appleLogo from "@assets/apple_logo.png";
import googleLogo from "@assets/google_logo.png";
import Button from "@components/Button";
import Input from "@components/Input";
import Layout from "@components/Layout";
import PasswordInput from "@components/PasswordInput";
import { signInWithOAuth } from "@helpers/supabase";
import { useTranslation } from "react-i18next";
import { Form, Link, useActionData, useNavigation } from "react-router-dom";
import { SignInActionData, SignInActionError } from "src/actions/signInAction";

export default function SignInPage() {
  const navigation = useNavigation();
  const signInActionData = useActionData() as SignInActionData;
  const { t } = useTranslation();

  const error = signInActionData?.error;

  const isSubmitting = ["loading", "submitting"].includes(navigation.state);

  const handleSignInWith = async (provider: "apple" | "google") => {
    await signInWithOAuth({ provider });
  };

  const translateError = (error: SignInActionError) => {
    switch (error.code) {
      case "invalid_credentials":
        return t("pages.account.signIn.invalidCredentials");
      case "validation_failed":
        return t("pages.account.signIn.invalidEmail");
      default:
        return error.message;
    }
  };

  return (
    <Layout>
      <div className="flex h-full flex-col space-y-11">
        <div className="shrink-0 space-y-6 text-center">
          <h1 className="heading">{t("pages.account.signIn.heading")}</h1>

          {error && <p className="subheading-error">{translateError(error)}</p>}
        </div>

        <div className="flex grow flex-col justify-center">
          <Form className="space-y-7" method="post">
            <fieldset className="space-y-7" disabled={isSubmitting}>
              <Input
                name="email"
                placeholder={t("shared.email")}
                type="email"
              />

              <PasswordInput name="password" />

              <Link
                className="float-right mr-4 text-sm font-bold text-[#A2A4AE] underline"
                to="/account/forgot-password"
              >
                {t("pages.account.signIn.forgotPassword")}
              </Link>
            </fieldset>

            <Button
              submitting={isSubmitting}
              type="submit"
              title={t("shared.signIn")}
            />
          </Form>
        </div>

        <div className="flex grow flex-col justify-center space-y-7">
          <button
            className="button-secondary"
            onClick={() => handleSignInWith("apple")}
          >
            <img src={appleLogo} alt="Apple logo" className="h-6 w-6" />
            {t("pages.account.signIn.signInWithApple")}
          </button>

          <button
            className="button-secondary"
            onClick={() => handleSignInWith("google")}
          >
            <img src={googleLogo} alt="Apple logo" className="h-6 w-6" />
            {t("pages.account.signIn.signInWithGoogle")}
          </button>
        </div>

        <div className="shrink-0 text-center">
          <Link className="text-lg font-bold" to="/onboarding">
            {t("pages.account.signIn.signUp")}
          </Link>
        </div>
      </div>
    </Layout>
  );
}
