export default function Arrow2Svg() {
  return (
    <svg
      fill="none"
      height="100"
      viewBox="0 0 217 217"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M176.613 171.298C167.721 171.084 159.19 170.844 150.664 170.636C148.964 170.587 147.765 170.554 145.624 170.844C144.031 171.042 140.664 171.536 141.238 173.198C141.857 174.522 142.464 175.383 145.412 175.755C153.028 176.678 160.721 176.461 168.354 176.286C175.094 176.128 180.232 175.904 186.187 175.411C188.968 175.181 189.84 173.643 189.098 171.179C188.432 168.968 187.788 166.687 186.718 164.642C182.902 157.25 178.908 149.986 174.968 142.647C174.484 141.733 174.162 140.99 173.431 139.984C172.533 138.773 171.947 137.842 170.623 138.46C169.359 139.035 169.746 140.961 170.133 141.892C173.195 149.742 179.784 165.828 179.939 166.764C175.26 164.756 170.888 162.995 166.607 160.987C136.86 146.86 108.373 130.732 84.0677 108.037C64.8282 90.0735 48.0478 70.1418 34.3561 47.6401C33.539 46.3165 32.5981 45.0466 31.668 43.8413C30.4315 42.1894 29.4958 41.5488 28.5972 42.1294C27.0582 43.4467 27.6117 44.781 27.9609 45.8839C28.627 48.095 29.7719 50.1934 30.8738 52.2326C45.8977 79.3556 66.3618 101.892 89.877 121.832C114.011 142.299 141.719 156.533 170.735 168.46C172.289 169.031 176 170.803 176.613 171.298Z"
        fill="white"
      />
    </svg>
  );
}
