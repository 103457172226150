export default function LinkSummarySvg() {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 64 65"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0519 38.0191C27.4552 38.0191 27.0574 37.8202 26.6596 37.4224C24.2727 35.0356 22.8804 31.8531 22.8804 28.4717C22.8804 25.0903 24.2727 21.9079 26.6596 19.521L37.9971 8.18348C40.3839 5.79663 43.5664 4.4043 46.9478 4.4043C50.3292 4.4043 53.5116 5.79663 55.8985 8.18348C60.8711 13.1561 60.8711 21.1123 55.8985 26.0849L50.727 31.2564C49.9313 32.052 48.7379 32.052 47.9423 31.2564C47.1467 30.4608 47.1467 29.2673 47.9423 28.4717L53.1138 23.3002C56.4952 19.9188 56.4952 14.3495 53.1138 10.9681C51.5226 9.3769 49.3346 8.38238 46.9478 8.38238C44.5609 8.38238 42.373 9.3769 40.7818 10.9681L29.4442 22.3057C27.853 23.8969 26.8585 26.0849 26.8585 28.4717C26.8585 30.8586 27.853 33.0465 29.4442 34.6377C30.2398 35.4334 30.2398 36.6268 29.4442 37.4224C29.0464 37.8202 28.6486 38.0191 28.0519 38.0191Z"
        fill="#121319"
      />

      <path
        d="M16.7142 60.0992C13.5318 60.0992 10.1504 58.9058 7.76356 56.32C5.3767 53.9332 3.98438 50.7507 3.98438 47.3693C3.98438 43.9879 5.3767 40.8055 7.76356 38.4186L12.9351 33.2471C13.7307 32.4515 14.9241 32.4515 15.7197 33.2471C16.5153 34.0427 16.5153 35.2362 15.7197 36.0318L10.5482 41.2033C8.95698 42.7945 7.96246 44.9825 7.96246 47.3693C7.96246 49.7562 8.95698 51.9441 10.5482 53.5354C13.9296 56.9167 19.4989 56.9167 22.8803 53.5354L34.2178 42.1978C35.8091 40.6066 36.8036 38.4186 36.8036 36.0318C36.8036 33.6449 35.8091 31.457 34.2178 29.8657C33.4222 29.0701 33.4222 27.8767 34.2178 27.0811C35.0134 26.2855 36.2069 26.2855 37.0025 27.0811C39.3893 29.4679 40.7817 32.6504 40.7817 36.0318C40.7817 39.4132 39.3893 42.5956 37.0025 44.9825L25.6649 56.32C23.2781 58.7069 19.8967 60.0992 16.7142 60.0992Z"
        fill="#121319"
      />
    </svg>
  );
}
