import BrainstormAnythingSvg from "@components/BrainstormAnythingSvg";
import Button from "@components/Button";
import FadeInDiv from "@components/FadeInDiv";
import ImageGenerationSvg from "@components/ImageGenerationSvg";
import LayoutOnboarding from "@components/LayoutOnboarding";
import LinkSummarySvg from "@components/LinkSummarySvg";
import YouTubeSummarySvg from "@components/YouTubeSummarySvg";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function ToolsPage() {
  const { t } = useTranslation();

  const className = "font-semibold text-[#121319] rounded-2xl p-4 space-y-6";

  return (
    <LayoutOnboarding>
      <div className="flex grow flex-col space-y-8">
        <div className="flex grow flex-col justify-center">
          <h1 className="heading">{t("pages.onboarding.tools.heading")}</h1>
        </div>

        <div className="container mx-auto max-w-sm grow">
          <div className="grid grid-cols-2 gap-5">
            <FadeInDiv className={`${className} bg-[#B0ECFF]`} delay={0}>
              <BrainstormAnythingSvg />

              <p>{t("pages.onboarding.tools.brainstormAnything")}</p>
            </FadeInDiv>

            <FadeInDiv className={`${className} bg-[#A2E2A9]`} delay={0.2}>
              <ImageGenerationSvg />

              <p>{t("pages.onboarding.tools.imageGeneration")}</p>
            </FadeInDiv>

            <FadeInDiv className={`${className} bg-[#FFF9D8]`} delay={0.4}>
              <LinkSummarySvg />

              <p>{t("pages.onboarding.tools.linkSummary")}</p>
            </FadeInDiv>

            <FadeInDiv className={`${className} bg-[#FF6262]`} delay={0.6}>
              <YouTubeSummarySvg />

              <p>{t("pages.onboarding.tools.youtubeSummary")}</p>
            </FadeInDiv>
          </div>
        </div>

        <div className="shrink-0">
          <Form className="flex flex-col gap-4" method="post">
            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      </div>
    </LayoutOnboarding>
  );
}
