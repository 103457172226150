import { motion } from "motion/react";

export default function FadeInDiv({
  children,
  className,
  delay,
}: {
  children: React.ReactNode;
  className: string;
  delay: number;
}) {
  return (
    <motion.div
      animate={{ opacity: 1 }}
      className={className}
      initial={{ opacity: 0 }}
      transition={{ delay, duration: 0.5, ease: "easeInOut" }}
    >
      {children}
    </motion.div>
  );
}
