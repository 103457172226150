import Arrow1Svg from "@components/Arrow1Svg";
import Arrow2Svg from "@components/Arrow2Svg";
import Button from "@components/Button";
import LayoutOnboarding from "@components/LayoutOnboarding";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function HelpPage() {
  const { t } = useTranslation();

  return (
    <LayoutOnboarding>
      <div className="flex grow flex-col space-y-8">
        <div className="flex grow flex-col justify-center">
          <h1 className="heading">{t("pages.onboarding.help.heading")}</h1>
        </div>

        <div className="container mx-auto max-w-sm grow">
          <div className="flex grow flex-col space-y-4">
            <div className="chat-bubble-green self-start">
              <span>💬</span>

              <span>{t("pages.onboarding.help.socialMedia")}</span>
            </div>

            <div className="chat-bubble-black relative ml-8 self-start">
              {t("pages.onboarding.help.whatTrendingTopicsCanITweetAbout")}

              <span className="absolute right-[-80px] top-[8px]">
                <Arrow1Svg />
              </span>
            </div>

            <div className="chat-bubble-green self-end">
              <span>💻</span>

              <span>{t("pages.onboarding.help.work")}</span>
            </div>

            <div className="chat-bubble-black mr-12 self-end">
              {t("pages.onboarding.help.writeAProfessionalCoverLetter")}
            </div>

            <div className="chat-bubble-green relative self-start">
              <span>😎</span>

              <span>{t("pages.onboarding.help.communication")}</span>

              <span className="absolute left-[10px] top-[40px]">
                <Arrow2Svg />
              </span>
            </div>

            <div className="chat-bubble-black self-end">
              {t("pages.onboarding.help.congratulateMyFriendOnHerBirthday")}
            </div>
          </div>
        </div>

        <div className="shrink-0">
          <Form className="flex flex-col gap-4" method="post">
            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      </div>
    </LayoutOnboarding>
  );
}
