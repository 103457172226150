export default function Arrow1Svg() {
  return (
    <svg
      fill="none"
      height="100"
      viewBox="0 0 216 216"
      width="100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_63_303)">
        <path
          d="M189.248 153.402C186.268 145.022 183.376 136.993 180.515 128.958C179.938 127.359 179.532 126.229 179.138 124.105C178.829 122.53 178.246 119.177 180.005 119.204C181.456 119.378 182.463 119.686 183.738 122.37C186.993 129.317 189.189 136.693 191.408 143.998C193.362 150.451 194.754 155.401 196.146 161.212C196.796 163.926 195.608 165.235 193.035 165.3C190.727 165.358 188.358 165.458 186.082 165.081C177.867 163.764 169.72 162.239 161.517 160.788C160.497 160.614 159.691 160.54 158.507 160.16C157.077 159.685 156.009 159.419 156.183 157.969C156.334 156.588 158.285 156.353 159.29 156.43C167.703 156.888 185.043 158.123 185.981 157.978C182.611 154.16 179.573 150.557 176.328 147.117C153.617 123.271 129.398 101.246 100.248 85.245C77.1735 72.5786 52.9979 62.863 27.3456 56.8842C25.833 56.5214 24.3328 56.0242 22.8972 55.5171C20.9417 54.8584 20.041 54.1695 20.3119 53.1346C21.0826 51.2611 22.523 51.3702 23.6798 51.3575C25.9883 51.2997 28.3393 51.7319 30.6207 52.1418C61.0792 57.9427 88.8801 70.3441 115.167 86.4551C142.148 102.989 164.324 124.865 184.717 148.704C185.745 150.003 188.587 152.975 189.248 153.402Z"
          fill="white"
        />
      </g>

      <defs>
        <clipPath id="clip0_63_303">
          <rect
            width="188.471"
            height="188.471"
            fill="white"
            transform="translate(187.087 0.814453) rotate(81.2389)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
