export default function LogoSvg() {
  return (
    <svg
      fill="none"
      height="41"
      viewBox="0 0 100 104"
      width="32"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M91.6631 48.436L91.6574 48.4285L91.6516 48.4209C86.6421 41.9022 78.6484 36.5489 68.0562 32.1835L51.0479 25.1739C40.4592 20.81 31.0452 18.9876 22.9636 20.1229L23.3575 22.9271L22.9636 20.1229C14.5645 21.3029 8.55761 25.8742 5.36198 33.6281C2.16635 41.3821 3.20805 48.8584 8.33687 55.6135C13.2718 62.1133 21.2362 67.4532 31.8249 71.8171L48.8332 78.8267C59.4254 83.192 68.8698 85.0254 77.0176 83.9292L77.027 83.9279L77.0364 83.9266C85.4355 82.7467 91.4424 78.1754 94.638 70.4214C97.8336 62.6675 96.792 55.1912 91.6631 48.436Z"
        stroke="white"
        strokeWidth="6.50971"
      />

      <path
        d="M69.1932 89.1781L69.198 89.1699L69.2028 89.1616C73.3209 82.0462 75.2245 72.6157 75.2245 61.1592V42.7631C75.2245 31.3104 73.3223 21.9122 69.1932 14.8729L66.7507 16.3056L69.1932 14.8729C64.9019 7.557 58.3866 3.74515 50 3.74515C41.6133 3.74515 35.098 7.557 30.8067 14.8729C26.6777 21.9122 24.7754 31.3104 24.7754 42.7631V61.1592C24.7754 72.6157 26.6791 82.0462 30.7972 89.1616L30.8019 89.1699L30.8067 89.1781C35.098 96.4939 41.6133 100.306 50 100.306C58.3866 100.306 64.9019 96.4939 69.1932 89.1781Z"
        stroke="white"
        strokeWidth="6.50971"
      />

      <path
        d="M27.4216 87.2235L27.4311 87.2236L27.4406 87.2236C35.6618 87.2322 44.7807 84.1656 54.7023 78.4373L70.6338 69.2393C80.5521 63.513 87.7401 57.1664 91.7718 50.0709L89.3097 48.672L91.7718 50.0709C95.9619 42.6966 96.0054 35.1483 91.8121 27.8852C87.6187 20.6222 81.0599 16.8857 72.5786 16.8272C64.4178 16.771 55.3275 19.8228 45.4093 25.5491L29.4778 34.7471C19.5561 40.4754 12.3409 46.8392 8.23779 53.9633L8.23305 53.9716L8.22836 53.9798C4.03827 61.3541 3.99478 68.9025 8.1881 76.1655C12.3814 83.4286 18.9402 87.1651 27.4216 87.2235Z"
        stroke="white"
        strokeWidth="6.50971"
      />
    </svg>
  );
}
