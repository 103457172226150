import navigateToNextOnboardingPageAction from "@actions/navigateToNextOnboardingPageAction";
import skipOnboardingLoader from "@loaders/skipOnboardingLoader";
import CompanyPage from "@pages/onboarding/CompanyPage";
import HelpPage from "@pages/onboarding/HelpPage";
import PotentialPage from "@pages/onboarding/PotentialPage";
import StartPage from "@pages/onboarding/StartPage";
import ToolsPage from "@pages/onboarding/ToolsPage";
import { Navigate, Outlet } from "react-router-dom";

const onboardingRoutes = [
  {
    path: "/onboarding",
    element: <Outlet />,
    loader: skipOnboardingLoader,
    children: [
      {
        element: <Navigate to="start" replace />,
        index: true,
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <CompanyPage />,
        path: "company",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <HelpPage />,
        path: "help",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <PotentialPage />,
        path: "potential",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <StartPage />,
        path: "start",
      },
      {
        action: navigateToNextOnboardingPageAction,
        element: <ToolsPage />,
        path: "tools",
      },
    ],
  },
];

export default onboardingRoutes;
