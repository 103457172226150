import Button from "@components/Button";
import FadeInDiv from "@components/FadeInDiv";
import LayoutOnboarding from "@components/LayoutOnboarding";
import { useTranslation } from "react-i18next";
import { Form } from "react-router-dom";

export default function PotentialPage() {
  const { t } = useTranslation();

  const className =
    "border-primary-green bg-primary-green-dark text-primary-green-light text-center rounded-2xl text-xl border p-4 font-bold";

  return (
    <LayoutOnboarding>
      <div className="flex grow flex-col space-y-8">
        <div className="flex grow flex-col justify-center">
          <h1 className="heading">{t("pages.onboarding.potential.heading")}</h1>
        </div>

        <div className="container mx-auto max-w-sm grow">
          <div className="grid grid-cols-2 gap-5">
            <FadeInDiv className={className} delay={0}>
              <span>✍️</span>

              <p>{t("pages.onboarding.potential.writeAnyText")}</p>
            </FadeInDiv>

            <FadeInDiv className={className} delay={0.2}>
              <span>📧</span>

              <p>{t("pages.onboarding.potential.composeEmails")}</p>
            </FadeInDiv>

            <FadeInDiv className={className} delay={0.4}>
              <span>💬</span>

              <p>{t("pages.onboarding.potential.summarize")}</p>
            </FadeInDiv>

            <FadeInDiv className={className} delay={0.6}>
              <span>📃</span>

              <p>{t("pages.onboarding.potential.translate")}</p>
            </FadeInDiv>

            <FadeInDiv className={className} delay={0.8}>
              <span>📝</span>

              <p>{t("pages.onboarding.potential.rephrase")}</p>
            </FadeInDiv>

            <FadeInDiv className={className} delay={1}>
              <span>🔎</span>

              <p>{t("pages.onboarding.potential.proofread")}</p>
            </FadeInDiv>
          </div>
        </div>

        <div className="shrink-0">
          <Form className="flex flex-col gap-4" method="post">
            <Button title={t("shared.continue")} type="submit" />
          </Form>
        </div>
      </div>
    </LayoutOnboarding>
  );
}
