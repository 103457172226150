import { redirect } from "react-router-dom";

const ORDERED_ONBOARDING_ROUTES = [
  "start",
  "help",
  "potential",
  "tools",
  "company",
];

export default async function navigateToNextOnboardingPageAction({
  request,
}: {
  request: Request;
}) {
  const formData = await request.formData();

  // NOTE: Get form data entries:
  console.log("formData", Object.fromEntries(formData));

  // TODO: Multiple checkbox values are not being captured correctly
  // const topics = formData.getAll("topics");
  // console.log("topics", topics);

  const next = getNext(request.url);

  if (!next) {
    return redirect("/sign-up/password");
  }

  const nextPathname = getNextPathName({ next, url: request.url });

  return redirect(nextPathname);
}

function getNext(url: string): string | undefined {
  const pathname = new URL(url).pathname;
  const current = pathname.split("/").pop();

  if (!current) return;

  const currentIndex = ORDERED_ONBOARDING_ROUTES.indexOf(current);
  const next = ORDERED_ONBOARDING_ROUTES[currentIndex + 1];

  return next;
}

function getNextPathName({ next, url }: { next: string; url: string }): string {
  const currentPathname = new URL(url).pathname;
  const paths = currentPathname.split("/");
  const basePathname = paths.slice(0, -1).join("/");
  const nextPathname = basePathname + "/" + next;

  return nextPathname;
}
