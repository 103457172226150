import { Link } from "react-router-dom";
import getAppStoreBadge from "../helpers/getAppStoreBadge";
import getGooglePlayBadge from "../helpers/getGooglePlayBadge";

export default function GooglePlayAndAppStoreBadgeLinks() {
  return (
    <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 sm:gap-0">
      <Link
        className="justify-self-center"
        target="_blank"
        to={import.meta.env.VITE_APP_URL_GOOGLE_PLAY_STORE as string}
      >
        <img
          alt="Get ChatAI Plus on Google Play"
          className="h-[71px] sm:h-20"
          src={getGooglePlayBadge()}
        />
      </Link>

      <Link
        className="justify-self-center"
        target="_blank"
        to={import.meta.env.VITE_APP_URL_APPLE_APP_STORE as string}
      >
        <img
          alt="Download ChatAI Plus on the App Store"
          className="h-20"
          src={getAppStoreBadge()}
        />
      </Link>
    </div>
  );
}
