import LayoutOnboarding from "@components/LayoutOnboarding";
import LogoSvg from "@components/LogoSvg";
import RadioInputButton from "@components/RadioInputButton";
import { useTranslation } from "react-i18next";
import { Form, Link, useSubmit } from "react-router-dom";

export default function StartPage() {
  const submit = useSubmit();
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    submit(event.currentTarget.form);
  };

  return (
    <LayoutOnboarding>
      <div className="flex grow flex-col space-y-8">
        <div className="flex shrink-0 items-center justify-between">
          <LogoSvg />

          <Link className="text-lg hover:opacity-80" to="/account/sign-in">
            {t("shared.signIn")}
          </Link>
        </div>

        <div className="flex grow flex-col justify-center space-y-6">
          <h1 className="heading">{t("pages.onboarding.start.heading")}</h1>

          <h2 className="text-center text-lg text-white">
            {t("pages.onboarding.start.subheading")}
          </h2>
        </div>

        <div className="my-8 flex grow flex-col justify-center">
          <Form className="flex flex-col gap-4" method="post">
            <RadioInputButton
              label={t("pages.onboarding.start.socialMediaPosts")}
              name="what_do_you_need_help_with_today"
              onChange={handleChange}
              value="social-media-posts"
            />

            <RadioInputButton
              label={t("pages.onboarding.start.writingEmails")}
              name="what_do_you_need_help_with_today"
              onChange={handleChange}
              value="writing-emails"
            />

            <RadioInputButton
              label={t("pages.onboarding.start.translatingText")}
              name="what_do_you_need_help_with_today"
              onChange={handleChange}
              value="translating-text"
            />

            <RadioInputButton
              label={t("pages.onboarding.start.summarizeArticles")}
              name="what_do_you_need_help_with_today"
              onChange={handleChange}
              value="summarize-articles"
            />

            <RadioInputButton
              label={t("pages.onboarding.start.somethingElse")}
              name="what_do_you_need_help_with_today"
              onChange={handleChange}
              value="something-else"
            />
          </Form>
        </div>
      </div>
    </LayoutOnboarding>
  );
}
