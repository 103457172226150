import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LayoutWhite from "../../components/LayoutWhite";

export default function HelpCenterPage() {
  const { t } = useTranslation();

  const className =
    "w-full rounded-2xl border-2 border-black inline-block p-4 text-center hover:opacity-50";

  return (
    <LayoutWhite>
      <div className="shrink-0">
        <h1 className="mb-11 font-bold">{t("pages.helpCenter.heading")}</h1>
      </div>

      <div className="flex grow flex-col justify-center space-y-5">
        <Link className={className} to="mailto:support@chataiplus.app">
          {t("pages.helpCenter.generalQuestionOrSuggestion")}
        </Link>

        <Link className={className} to="/help-center/cancelation">
          {t("pages.helpCenter.cancelSubscription")}
        </Link>

        <Link className={className} to="/help-center/refund">
          {t("pages.helpCenter.requestRefund")}
        </Link>
      </div>
    </LayoutWhite>
  );
}
