import { Trans } from "react-i18next";

export default function Features() {
  return (
    <div className="space-y-8">
      <div className="space-x-2 text-center">
        <span className="text-xl">💬</span>

        <span className="uppercase">
          <Trans
            components={{ bold: <span className="font-bold" /> }}
            i18nKey="pages.checkout.plans.features.unlimitedChatMessages"
          />
        </span>
      </div>

      <div className="space-x-2 text-center">
        <span className="text-xl">✨</span>

        <span className="uppercase">
          <Trans
            components={{ bold: <span className="font-bold normal-case" /> }}
            i18nKey="pages.checkout.plans.features.answersFromGpt4o"
          />
        </span>
      </div>

      <div className="space-x-2 text-center">
        <span className="text-xl">🌁</span>

        <span className="uppercase">
          <Trans
            components={{ bold: <span className="font-bold" /> }}
            i18nKey="pages.checkout.plans.features.infiniteImageGenerations"
          />
        </span>
      </div>

      <div className="space-x-2 text-center">
        <span className="text-xl">🔗</span>

        <span className="uppercase">
          <Trans
            components={{ bold: <span className="font-bold" /> }}
            i18nKey="pages.checkout.plans.features.linkAndYoutubeSummary"
          />
        </span>
      </div>
    </div>
  );
}
