export default function YouTubeSummarySvg() {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 86 63"
      width="60"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5376 2.06303H68.3072C76.5952 2.06303 83.3495 8.83131 83.3495 17.157V45.4577C83.3495 53.7833 76.5952 60.5516 68.3072 60.5516H17.5376C9.24946 60.5516 2.49528 53.785 2.49528 45.4577V17.157C2.49528 8.83131 9.24957 2.06303 17.5376 2.06303Z"
        stroke="#121319"
        strokeWidth="3.44051"
      />

      <path
        d="M57.2953 32.1704L57.2949 32.1701L33.4616 18.2107C33.4616 18.2107 33.4616 18.2107 33.4615 18.2107L33.4615 18.2107L57.2953 32.1704ZM57.2953 32.1704C57.2955 32.171 57.2956 32.1715 57.2956 32.1721L57.295 32.1722L33.4627 46.1276C33.4626 46.1277 33.4626 46.1277 33.4625 46.1278C33.4617 46.1282 33.4612 46.1285 33.4607 46.1287V18.2106L57.2953 32.1704Z"
        stroke="#121319"
        strokeWidth="3.44051"
      />
    </svg>
  );
}
