export default function ImageGenerationSvg() {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 66 66"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_63_332)">
        <path
          d="M0.451416 58.7625C0.451416 62.5693 3.55026 65.6548 7.34368 65.6548H58.9288C62.7355 65.6548 65.821 62.5559 65.821 58.7625V7.17742C65.821 3.37064 62.7222 0.285156 58.9288 0.285156H7.34368C3.5369 0.285156 0.451416 3.384 0.451416 7.17742V58.7625ZM58.9288 62.3823H7.34368C5.35347 62.3823 3.7239 60.7527 3.7239 58.7625V50.1472L16.1193 37.7518L26.7115 48.344C27.3526 48.9851 28.3811 48.9851 29.0222 48.344L48.1496 29.2166L62.5486 43.6156V58.7625C62.5486 60.7527 60.919 62.3823 58.9288 62.3823ZM7.34368 3.55764H58.9288C60.919 3.55764 62.5486 5.18721 62.5486 7.17742V38.9807L49.2983 25.7438C48.6572 25.1026 47.6287 25.1026 46.9875 25.7438L27.8602 44.8711L17.268 34.279C16.6269 33.6378 15.5984 33.6378 14.9572 34.279L3.7239 45.5123V7.17742C3.7239 5.18721 5.35347 3.55764 7.34368 3.55764Z"
          fill="#121319"
        />

        <path
          d="M20.714 26.4789C25.3089 26.4789 29.0355 22.7389 29.0355 18.1574C29.0355 13.5759 25.2955 9.83594 20.714 9.83594C16.1326 9.83594 12.3926 13.5759 12.3926 18.1574C12.3926 22.7389 16.1192 26.4789 20.714 26.4789ZM20.714 13.1084C23.5057 13.1084 25.763 15.3791 25.763 18.1574C25.763 20.9357 23.4923 23.2064 20.714 23.2064C17.9358 23.2064 15.6651 20.9357 15.6651 18.1574C15.6651 15.3791 17.9224 13.1084 20.714 13.1084Z"
          fill="#121319"
        />
      </g>

      <defs>
        <clipPath id="clip0_63_332">
          <rect
            fill="white"
            height="65.3696"
            transform="translate(0.451294 0.286133)"
            width="65.3696"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
