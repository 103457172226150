export default function BrainstormAnythingSvg() {
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 68 68"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M61.5004 31.8499L61.4951 31.8431C58.2222 27.5842 52.9576 24.036 45.8766 21.1177L34.4063 16.3905C27.327 13.4728 21.1135 12.2899 15.8378 13.0311C10.4176 13.7925 6.58417 16.7151 4.52813 21.704C2.47209 26.6928 3.13314 31.4677 6.44292 35.8271C9.66446 40.0702 14.9071 43.6086 21.9865 46.5263L33.4567 51.2535C40.5377 54.1718 46.7737 55.3632 52.0969 54.6471L52.1055 54.6459C57.5256 53.8845 61.3591 50.9618 63.4151 45.973C65.4712 40.9842 64.8101 36.2092 61.5004 31.8499Z"
        stroke="#121319"
        strokeWidth="2.96242"
      />

      <path
        d="M46.2996 58.5326L46.3039 58.5251C48.9944 53.8764 50.2689 47.6569 50.2689 39.9981V27.5919C50.2689 19.9349 48.9951 13.7395 46.2996 9.14419C43.5303 4.42304 39.3675 1.99242 33.9716 1.99242C28.5757 1.99242 24.4128 4.42304 21.6435 9.14419C18.9481 13.7395 17.6742 19.9349 17.6742 27.5919V39.9981C17.6742 47.6569 18.9487 53.8764 21.6392 58.5252L21.6435 58.5326C24.4128 63.2538 28.5757 65.6844 33.9716 65.6844C39.3675 65.6844 43.5303 63.2538 46.2996 58.5326Z"
        stroke="#121319"
        strokeWidth="2.96242"
      />

      <path
        d="M18.7497 56.8623L18.7584 56.8623C24.1296 56.8679 30.1531 54.8619 36.7858 51.0326L47.5298 44.8295C54.161 41.001 58.8895 36.8 61.5214 32.168C64.2253 27.4092 64.2489 22.5888 61.551 17.9158C58.853 13.2428 54.6666 10.853 49.1933 10.8153C43.866 10.7786 37.8636 12.7731 31.2324 16.6016L20.4884 22.8047C13.8557 26.6341 9.10671 30.8476 6.42597 35.502L6.4217 35.5095C3.71771 40.2683 3.69415 45.0888 6.3921 49.7617C9.09004 54.4347 13.2764 56.8246 18.7497 56.8623Z"
        stroke="#121319"
        strokeWidth="2.96242"
      />
    </svg>
  );
}
