import { ReactNode } from "react";

export default function LayoutOnboarding({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <main className="custom-container flex min-h-dvh flex-col hyphens-auto pb-14 pt-8">
      {children}
    </main>
  );
}
